import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getStrengthPage({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/strength/page/search', params, this);
        console.info(list);
        return list;
    },
    addStrength({ state, commit }, params) {
        let list = httpV2.post('/official/v1.0/strength', params, this);
        return list;
    },
    editStrength({ state, commit }, params) {
        let promise = httpV2.put("/official/v1.0/strength?id=" + params.strengthId, params, this);
        return promise;
    },
    deleteStrength({ state, commit }, params) {
        let promise = httpV2.delete("/official/v1.0/strength", params, this);
        return promise;
    },
    //排序
    putSortStrength({ state, commit }, params) {
        let promise = httpV2.put(`/official/v1.0/strength/${params.id}/sort/${params.sort}`, params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};

import cache from "./cache";


export default {
    getDictValue(tag, code) {
        var dict = cache.get('dictItems_' + tag);
        if (dict == undefined || dict == null || dict.length == 0) {
            return code;
        }
        var code = dict.find(x => x.code == code);
        if (code) {
            return code.name;
        }
    }
}
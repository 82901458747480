import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getUserCommentPage({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/user/comment/page/search', params, this);
        console.info(list);
        return list;
    },
    getMessageById({ state, commit }, params) {
        let list = httpV2.get('/official/v1.0/user/comment', params, this);
        return list;
    },
    getAutoStatus({ state, commit }, params) {
        let promise = httpV2.get("/official/v1.0/user/comment/status", params, this);
        return promise;
    },
    changeAutoStatus({ state, commit }, params) {
        let promise = httpV2.put("/official/v1.0/user/comment/auto", params, this);
        return promise;
    },
    pushMessage({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/user/comment/push", params, this);
        return promise;
    },
    exportExcel({ state, commit }, params) {
        let promise = httpV2.getFile("/official/v1.0/user/comment/export", params, this);
        return promise;
    },
    remarkUserComment({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/user/comment/remark", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
import Vue from 'vue'
import SparkMD5 from 'spark-md5';


const preventReClick = Vue.directive('preventReClick', {
    inserted: function (el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 800)
            }
        })
    }
});


export default {
    preventReClick: preventReClick,
    getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

            if (data[i].children.length < 1) {
                // children若为空数组，则将children设为undefined
                data[i].children = undefined;
            } else {
                // children若不为空数组，则继续 递归调用 本方法
                this.getTreeData(data[i].children);
            }
        }
        return data;
    },
    async calculateMD5(file) {
        const _this = this;
        var fileReader = new FileReader();
        var dataFile = file.raw;
        if (file.raw == undefined) dataFile = file;
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        var spark = new SparkMD5.ArrayBuffer();
        //获取文件二进制数据
        fileReader.readAsArrayBuffer(dataFile);
        // 下面要注意的是 fileReader.onload 回调方法是异步的，
        // 需要用到await 把它变成同步的，不然文件上传的时候是拿不到md5的值的
        await new Promise((resolve, reject) => {
            fileReader.onload = function (e) {
                spark.append(e.target.result.slice(0, 10 * 1024 * 1024));
                const md5 = spark.end();
                file.md5 = md5;
                resolve();
            };
        });
    },
}
import httpV2 from '@/utils/httpV2';
import { Header } from 'element-ui';
import cache from '../utils/cache';
import Config from '@/utils/config';

const state = {
    customerValue: [1] // 客户价值
};
const getters = {
    getCustomerValue: state => {
        return state.customerValue;
    }
};

const mutations = {
    customerValue(state, list) {
        state.customerValue = list;
    }
};

const actions = {
    customerValue({ commit }) {
        commit('customerValue', []);
        // api.customerValueList()
        //   .then(list => (commit('customerValue', list)))
    },
    //获取所有的字典
    async getDictList({ state, commit }, params) {

        let tenant = cache.get(Config.currentTenant);
        if (tenant == null) {
            var arr = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/tenants/byuser', {}, this);
            var tenantList = [];
            arr.forEach(element => {
                if (element.app_id == 10 && element.permission) {
                    tenantList.push(element);
                }
            });
            var currentTenant;
            let tenant = cache.get(Config.currentTenant);
            if (tenant) {
                //没有租户默认选第一个
                currentTenant = tenant;
            } else {
                currentTenant = tenantList[0];
            }
            cache.set(Config.currentTenant, currentTenant);
            dict = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/summary', {}, this);
            cache.set('dictSummary' + currentTenant.tenant_id, dict);
        } else {
            //租户ID不为空
            var currentTenant;
            let tenant = cache.get(Config.currentTenant);
            if (tenant) {
                //没有租户默认选第一个
                currentTenant = tenant;
            } else {
                currentTenant = tenantList[0];
            }

            var dict = cache.get('dictSummary' + currentTenant.tenant_id);
            if (dict == undefined || dict == null || dict.length == 0) {
                dict = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/summary', {}, this);
                cache.set('dictSummary' + currentTenant.tenant_id, dict);
            }
        }
        return dict;
    },

    getDicItems({ dispatch, state, commit }, params) {
        var that = this;
        return new Promise(async(resolve, reject) => {

            var currentTenant;
            let tenant = cache.get(Config.currentTenant);
            if (tenant) {
                //没有租户默认选第一个
                currentTenant = tenant;
            } else {
                currentTenant = tenantList[0];
            }


            var dictItems = cache.get('dictItems_' + params.tag + currentTenant.tenant_id);
            if (dictItems && dictItems != null) {
                resolve(dictItems);
            } else {
                var dictSummary = cache.get('dictSummary' + currentTenant.tenant_id);
                if (!dictSummary || dictSummary == null) {
                    // dictSummary =await that.getDictList;
                    dispatch('getDictList', {}).then(res => {
                        dictSummary = res;
                        var element = dictSummary.find(x => x.dict_code == params.tag);
                        if (element && element.api_url != '') {
                            httpV2.get(process.env.VUE_APP_SDP + element.api_url, {}, this).then(data => {
                                cache.set('dictItems_' + params.tag + currentTenant.tenant_id, data);
                                cache.set( params.tag, data);
                                resolve(data);
                            });
                        }
                    });
                } else {
                    var element = dictSummary.find(x => x.dict_code == params.tag);
                    if (element && element.api_url != '') {
                        httpV2.get(process.env.VUE_APP_SDP + element.api_url, {}, this).then(data => {
                            cache.set('dictItems_' + params.tag + currentTenant.tenant_id, data);
                            cache.set( params.tag, data);
                            resolve(data);
                        });
                    }
                }
            }
        });
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};

// 根据status id 获取对应的状态文字
export function getStatusText(status = 0) {
    const item = state.taskStatus.find(s => s.id === status);
    return item ? item.name : '';
}
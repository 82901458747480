import httpV2 from '@/utils/httpV2';
import { Header } from 'element-ui';
import cache from '../utils/cache';

const state = {

};
const getters = {};

const mutations = {};

const actions = {    
    async getDict({ state, commit }, dictName) {
        var dictList = cache.get(dictName);
        if (dictList == undefined || dictList == null || dictList.length == 0) {
            let list = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/'+dictName+'/items', {}, this);
            state[dictName]=list;
            cache.set(dictName, list);
        } else {
            state[dictName]=dictList;
        }
    }    
};
export default {
    getters,
    state,
    mutations,
    actions
};

import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getArticlePage({ state, commit }, params) {
        let promise = httpV2.get2("/official/v1.0/home/article/page/search", params, this);
        return promise;
    },
    getHomeArticle({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/home/article", params, this);
        return promise;
    },
    homeArticleEdit({ state, commit }, params) {
        let promise = httpV2.put("/official/v1.0/home/article", params, this);
        return promise;
    },
    deleteArticle({ state, commit }, id) {
        let promise = httpV2.delete("/official/v1.0/home/article/" + id, {}, this);
        return promise;
    },
    //文章排序
    putSortArticle({ state, commit }, params) {
        let promise = httpV2.put(`/official/v1.0/home/article/${params.id}/sort/${params.sort}`, params, this);
        return promise;
    },
    //复制文章
    copyArticle({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/home/article/copy", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
 
    //分页 获取自定义参数列表
    getCustomParameterPage({ state, commit }, params) {
        return httpV2.get('/official/v1.0/custom/parameter/page/search', params, this);
    },

    addCustomParameter({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/custom/parameter", params, this);
        return promise;
    },
    updateCustomParameter({ state, commit }, params) {
        let promise = httpV2.put("/official/v1.0/custom/parameter/" + params.customParameterId, params, this);
        return promise;
    },
    
    // 删除
    deleteCustomParameter({ state, commit }, params) {
        let promise = httpV2.delete("/official/v1.0/custom/parameter/"+ params.id, params, this);
        return promise;
    },



    //分页获取产品列表
    getGoodsPageForCustomParameter({ state, commit }, params) {
        let promise = httpV2.post(`/official/v1.0/goods/page/search?page=${params.page}&limit=${params.limit}`, params, this);
        return promise;
    },


    // 绑定自定义参数和产品关系
    bindCustomParameter({ state, commit }, params) {
        return httpV2.post('/official/v1.0/custom/parameter/' + params.customParameterId + '/bind', params.goodsIdList, this);
    },
    //解绑自定义参数和产品关系
    unBindCustomParameter({ state, commit }, params) {
        return httpV2.post('/official/v1.0/custom/parameter/' + params.customParameterId + '/unBind', params.goodsIdList, this);
    },

    // 导入文件
    importCustomParameter({ state, commit }, params) {
        return httpV2.post('/official/v1.0/custom/parameter/import', params, this);
    },
    /**
     * 导出模板
     */
    downExcelTemplateCustomParameter({ state, commit }, params) {
        let promise = httpV2.getFile("/official/v1.0/custom/parameter/template", params, this);
        return promise;
    },


    // 所属类目
    customParameterClassifyTree({ state, commit }, params) {
        return httpV2.get('/arc/v1.0/goods/classify/tree', params, this);
    }, 
    
    
};

export default {
    getters,
    state,
    mutations,
    actions
};
/*
 * @Author: laizerong 1369743864@qq.com
 * @Date: 2022-07-18 09:26:39
 * @LastEditors: laizerong 1369743864@qq.com
 * @LastEditTime: 2022-07-18 16:43:49
 * @FilePath: \nuxtjs-based:\project\Aigo.OfficialWebsite.AdminWeb\src\store\classAdmin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //获取类目树(搜索)
    getGoodsList({ state, commit }, params) {
        let promise = httpV2.get("/arc/v1.0/goods/classify/tree/search", params, this);
        return promise;
    },
    // 按id获取产品类目
    getClassifyInfo({ state, commit }, params) {
        let promise = httpV2.get(`/arc/v1.0/goods/classify/${params.id}`, params, this);
        return promise;
    },
    // 修改产品类目
    uploadClassify({ state, commit }, params) {
        let promise = httpV2.put(`/arc/v1.0/goods/classify/${params.goodsClassifyId}`, params, this);
        return promise;
    },
    // 获取产品类目关联参数
    getClassifyParams({ state, commit }, params) {
        let promise = httpV2.get('/arc/v1.0/goods/classify/parameter/page/search', params, this)
        return promise;
    },
    // 同步OAS产品组数据
    classifySync({ state, commit }, params) {
        let promise = httpV2.post('/arc/v1.0/goods/classify/sync', params, this)
        return promise;
    },
    // 根据类目ID获取自定义图片排序配置
    listCustomPictureSortConfig({ state, commit }, params) {
        let promise = httpV2.get(`/arc/v1.0/custom/picture/sort/config/${params.goodsClassifyId}`, params, this)
        return promise;
    },

    // 获取特定选择的类目
    getChooseHasGoodsTree({ state, commit }, params) {
        let promise = httpV2.get("/arc/v1.0/goods/classify/list/chooseHasGoodsTree", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getFalconPlanPage({ state, commit }, params) {
        let promise = httpV2.post(`/official/v1.0/falcon/plan/page/search?page=${params.page}&limit=${params.limit}`, params, this);
        return promise;
    },
    addFalconPlan({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/falcon/plan", params, this);
        return promise;
    },
    putFalconPlan({ state, commit }, params) {
        let promise = httpV2.put("/official/v1.0/falcon/plan/" + params.falconPlanId, params, this);
        return promise;
    },
    deleteFalconPlan({ state, commit }, params) {
        let promise = httpV2.delete("/official/v1.0/falcon/plan", params, this);
        return promise;
    },

    getFalconPlanTopSort({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/falcon/plan/top', params, this);
        return list;
    },
    putFalconPlanTopSort({ state, commit }, params) {
        let list = httpV2.put('/official/v1.0/falcon/plan/sort', params, this);
        return list;
    },

    getConfigLang({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/falcon/plan/config/lang/list', params, this);
        return list;
    },
    putConfigLang({ state, commit }, params) {
        let list = httpV2.put('/official/v1.0/falcon/plan/config/lang', params, this);
        return list;
    },
    
    
};

export default {
    getters,
    state,
    mutations,
    actions
};
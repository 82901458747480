import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getWorkerPage({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/business_worker/pagelist", params, this);
        return promise;
    },
    getWorkerById({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/business_worker/get", params, this);
        return promise;
    },
    updateWorker({ state, commit }, params) {
        let promise = httpV2.put("/v1.0/business_worker/edit", params, this);
        return promise;
    },
    loadWorkerProvinces({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/business_worker/queryprovinces", params, this);
        return promise;
    },
    loadWorkerDistricts({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/business_worker/querydistricts", params, this);
        return promise;
    },
    importWorker({ state, commit }, params) {
        let promise = httpV2.postFileData("/v1.0/business_worker/import", params, this);
        return promise;
    },
    exportWorker({ state, commit }, params) {
        let promise = httpV2.getFile("/v1.0/business_worker/export", params, this);
        return promise;
    },
    delWorker({ state, commit }, params) {
        let promise = httpV2.delete("/v1.0/business_worker/del", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};

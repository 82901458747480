import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getJoinPage({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/join/us/page/search', params, this);
        console.info(list);
        return list;
    },
    addJoin({ state, commit }, params) {
        let list = httpV2.post('/official/v1.0/join/us', params, this);
        return list;
    },
    editJoin({ state, commit }, params) {
        let promise = httpV2.put("/official/v1.0/join/us?id=" + params.joinUsId, params, this);
        return promise;
    },
    deleteJoin({ state, commit }, params) {
        let promise = httpV2.delete("/official/v1.0/join/us", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
import httpV2 from "@/utils/httpV2";
import cache from "../utils/cache";

const state = {
    token: localStorage.getItem('token') || '',
    loading: true,

}
const getters = {

    getLoading: state => {
        return state.loading
    }

}

const mutations = {
    /**
     * 设置进度条
     * @param state 
     * @param status 
     */
    setLoading(state, status) {
        state.loading = status
    },


}

const actions = {

    //oss签名
    async getSignature({
        state,
        commit
    }, params) {
        var ossSignature = cache.get('ossSignature');
        var times = new Date().getTime() - 1000;
        if (ossSignature == undefined || ossSignature == null || ossSignature.expire < times) {
            var result = await httpV2.get(process.env.VUE_APP_OSSURL + "/v1.0/system/oss/signature", params, this);
            ossSignature = result;
            cache.set('ossSignature', result);
        }
        return ossSignature;
    },
    //oss签名
    postOss({ state, commit }, { params, url }) {
        let promise = httpV2.postFormData(process.env.VUE_APP_OSS_Head, params, this);
        return promise;
    },
    // getBsCountry({ state, commit }, params) {
    //     let list = httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/bs_countrys/items',{},this);
    //     return list;
    // },
    addToFileLibrary({ state, commit }, params) {
        let promise = httpV2.post("/v1.0/file_library/add", params, this);
        return promise;
    },
    //获取租户列表
    getbyUser({
        state,
        commit
    }, params) {
        let promise = httpV2.get(process.env.VUE_APP_SDP + "/v1.0/tenants/byuser", {}, this);
        return promise

    },
    //获取租户列表
    getMenu({
        state,
        commit
    }, params) {
        let promise = httpV2.get(process.env.VUE_APP_SDP + "/v1.0/system/module/tree", {}, this);
        return promise

    },
    setLoading({
        state,
        commit
    }, status) {
        commit("setLoading", status)
    },
    fullsync({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/full_sync", params, this);
        return promise;
    },
    postFormDataOss({ state, commit }, { params, url }) {
        let promise = httpV2.postFormData2(url, params, this);
        return promise;
    },
}

export default {
    getters,
    state,
    mutations,
    actions
}
import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getKeywordPage({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/keyword/statistics/page/search', params, this);
        console.info(list);
        return list;
    },
    exportKeyword({ state, commit }, params) {
        let promise = httpV2.getFile("/official/v1.0/keyword/statistics/export", params, this);
        return promise;
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};
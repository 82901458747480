import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
   

    // 新增用户回复操作
    addUserReply({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/user/reply", params, this);
        return promise;
    },
    //  导出 用户留言发送对象
    exportUserReplySendEmail({ state, commit }, params) {
        let promise = httpV2.postExportFileData("/official/v1.0/user/reply/export", params, this);
        return promise;
    },
    // 用户留言 邮件模板语言
    getUserReplyEmailTemplateLangs({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/user/reply/email/template/langs', params, this);
        return list;
    },
 
};

export default {
    getters,
    state,
    mutations,
    actions
};
import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //获取BannerList
    getBannerPage({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/banner_theme/pagelist', params, this);
        console.info(list);
        return list;
    },
    getBannerDetail({ state, commit }, params) {
        let list = httpV2.get('/official/v1.0/banner_theme/get', params, this);
        console.info(list);
        return list;
    },
    bannerSave({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/banner_theme/save", params, this);
        return promise;
    },
    bannerDel({ state, commit }, params) {
        let promise = httpV2.delete("/official/v1.0/banner_theme/del", params, this);
        return promise;
    },

    //获取BannerList

    getBanner({ state, commit }, params) {
        let list = httpV2.get2('/official/v1.0/banner/page/search', params, this);
        return list;
    },
    //banner排序
    putSortBanner({ state, commit }, params) {
        let promise = httpV2.put(`/official/v1.0/banner/${params.id}/sort/${params.sort}`, params, this);
        return promise;
    },
    //删除banner
    deleteBanner({ state, commit }, params) {
        let promise = httpV2.delete(`/official/v1.0/banner/${params.id}`, params, this);
        return promise;
    },
    addBanner({ state, commit }, params) {
        let promise = httpV2.post('/official/v1.0/banner', params, this);
        return promise;
    },
    updateBanner({ state, commit }, params) {
        let promise = httpV2.put(`/official/v1.0/banner/${params.bannerId}`, params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};

import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getActivityPage({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/activity/pagelist", params, this);
        return promise;
    },
    getAgbsActivityList({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/activity_agbs/effect_activity_list", params, this);
        return promise;
    },
    agbsActivityFormInit({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/activity/forminit", params, this);
        return promise;
    },
    saveActivity({ state, commit }, params) {
        let promise = httpV2.put("/v1.0/activity/save", params, this);
        return promise;
    },
    delActivity({ state, commit }, params) {
        let promise = httpV2.delete("/v1.0/activity/del", params, this);
        return promise;
    },
    getImportBarCodes({ state, commit }, params) {
        let promise = httpV2.post("/v1.0/activity/import_barcodes", params, this);
        return promise;
    },
    importActivityGoods({ state, commit }, params) {
        let promise = httpV2.postFileData("/v1.0/activity/import", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};

import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getSeoPage({ state, commit }, params) {
        let promise = httpV2.get2("/official/v1.0/seo/page/search", params, this);
        return promise;
    },
    getSeoList({ state, commit }, params) {
        let promise = httpV2.get2("/official/v1.0/seo/list", params, this);
        return promise;
    },

    /**
     * 导出【SEO管理】excel
     */
    exportSeoExcel({ state, commit }, params) {
        let promise = httpV2.getFile("/official/v1.0/seo/export", params, this);
        return promise;
    },


    /**
     * 导出【SEO管理】模板
     */
    downExcelTemplate({ state, commit }, params) {
        let promise = httpV2.getFile("/official/v1.0/seo/template", params, this);
        return promise;
    },

    createSEO({ state, commit }, params) {
        let promise = httpV2.post("/official/v1.0/seo/add", params, this);
        return promise;
    },

    updateSEO({ state, commit }, params) {
        let promise = httpV2.put("/official/v1.0/seo?id=" + params.id, params, this);
        return promise;
    },

    deleteSEO({ state, commit }, id) {
        let promise = httpV2.delete("/official/v1.0/seo/" + id, {}, this);
        return promise;
    }



};

export default {
    getters,
    state,
    mutations,
    actions
};
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [{
            path: '/signin',
            name: 'signin',
            component: resolve => require(['../views/oicd/Signin.vue'], resolve),
            meta: {
                keepAlive: false //此组件不需要被缓存
            }
        },
        {
            path: '/signout',
            name: 'SiginOut',
            component: resolve => require(['../views/oicd/SiginOut.vue'], resolve),
            meta: {
                keepAlive: false //此组件不需要被缓存
            }
        },
        {
            path: '/signin-oidc',
            name: 'login',
            component: resolve => require(['../views/oicd/Login.vue'], resolve),
            meta: {
                keepAlive: false //此组件不需要被缓存
            }
        },

        {
            path: '/',
            redirect: '/signin-oidc'
        },
        {
            path: '/',
            component: () =>
                import ( /* webpackChunkName: "home" */ '../components/common/Home.vue'),
            meta: { title: '自述文件' },
            children: [
                //后台路由配置
                {
                    path: '/dashboard',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../components/page/Dashboard.vue'),
                    meta: { title: '展示' }
                },
                //模板
                {
                    path: '/icon',
                    component: () =>
                        import ( /* webpackChunkName: "icon" */ '../components/page/Icon.vue'),
                    meta: { title: '自定义图标' }
                },
                {
                    path: '/table',
                    component: () =>
                        import ( /* webpackChunkName: "table" */ '../components/page/BaseTable.vue'),
                    meta: { title: '基础表格' }
                },
                {
                    path: '/tabs',
                    component: () =>
                        import ( /* webpackChunkName: "tabs" */ '../components/page/Tabs.vue'),
                    meta: { title: 'tab选项卡' }
                },
                {
                    path: '/form',
                    component: () =>
                        import ( /* webpackChunkName: "form" */ '../components/page/BaseForm.vue'),
                    meta: { title: '基本表单' }
                },
                {
                    // 富文本编辑器组件
                    path: '/editor',
                    component: () =>
                        import ( /* webpackChunkName: "editor" */ '../components/page/VueEditor.vue'),
                    meta: { title: '富文本编辑器' }
                },
                {
                    // markdown组件
                    path: '/markdown',
                    component: () =>
                        import ( /* webpackChunkName: "markdown" */ '../components/page/Markdown.vue'),
                    meta: { title: 'markdown编辑器' }
                },
                {
                    // 图片上传组件
                    path: '/upload',
                    component: () =>
                        import ( /* webpackChunkName: "upload" */ '../components/page/Upload.vue'),
                    meta: { title: '文件上传' }
                },
                {
                    // vue-schart组件
                    path: '/charts',
                    component: () =>
                        import ( /* webpackChunkName: "chart" */ '../components/page/BaseCharts.vue'),
                    meta: { title: 'schart图表' }
                },
                {
                    // 国际化组件
                    path: '/i18n',
                    component: () =>
                        import ( /* webpackChunkName: "i18n" */ '../components/page/I18n.vue'),
                    meta: { title: '国际化' }
                },
                {
                    path: '/404',
                    component: () =>
                        import ( /* webpackChunkName: "404" */ '../components/page/404.vue'),
                    meta: { title: '404' }
                },
                {
                    path: '/403',
                    component: () =>
                        import ( /* webpackChunkName: "403" */ '../components/page/403.vue'),
                    meta: { title: '403' }
                },
                {
                    path: '/donate',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../components/page/Donate.vue'),
                    meta: { title: '支持作者' }
                },
                {
                    path: '/ow/strength/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/strength/index.vue'),
                    meta: { title: '公司实力' }
                },
                {
                    path: '/ow/certificate/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/certificate/index.vue'),
                    meta: { title: '证书图片' }
                },
                {
                    path: '/ow/message/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/userComment/index.vue'),
                    meta: { title: '用户留言' }
                },
                {
                    path: '/ow/join/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/joinUs/index.vue'),
                    meta: { title: '加入我们' }
                },
                {
                    path: '/ow/article/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/homeArticle/index.vue'),
                    meta: { title: '首页文章' }
                },
                {
                    path: '/ow/informationV2/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/informationType/list.vue'),
                    meta: { title: '信息服务' }
                },
                {
                    path: '/ow/subscribe/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/subscribe/index.vue'),
                    meta: { title: '订阅管理' }
                },
                {
                    path: '/ow/country/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/country/index.vue'),
                    meta: { title: '国家配置' }
                },
                {
                    path: '/ow/seo/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/seo/index.vue'),
                    meta: { title: 'SEO管理' }
                },
                {
                    path: '/ow/bannerAdmin/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/bannerAdmin/index'),
                    meta: { title: 'Banner管理' }
                },
                {
                    path: '/ow/classAdmin/index',
                    component: () =>
                        import ('../views/classAdmin/index.vue'),
                    meta: { title: '类目管理' }
                },
                {
                    path: '/home',
                    component: () =>
                        import ('../views/classAdmin/index.vue'),
                    meta: { title: '类目管理' }
                },
                {
                    path: '/ow/keyword/index',
                    component: () =>
                        import ('../views/keyword/index.vue'),
                    meta: { title: '搜索关键词' }
                },
                {
                    path: '/ow/falconPlan/index',
                    component: () =>
                        import ('../views/falconPlan/index.vue'),
                    meta: { title: '猎鹰计划' }
                },
                {
                    path: '/ow/customParameter/index',
                    component: () =>
                        import ('../views/customParameter/index.vue'),
                    meta: { title: '自定义参数' }
                }

            ]
        },
        {
            path: '/login',
            component: () =>
                import ( /* webpackChunkName: "login" */ '../components/page/Login.vue'),
            meta: { title: '登录' }
        },
        {
            path: '*',
            redirect: '/404'
        },
        {
            name: 'blank',
            path: '/waiting/blank',
            component: () =>
                import ( /* webpackChunkName: "donate" */ '../views/waiting/blank.vue'),
            meta: { title: '空白页' }
        }
    ]
});
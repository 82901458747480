import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getCertificatePage({ state, commit }, params) {
        let list = httpV2.get('/official/v1.0/certificate/page/search', params, this);
        console.info(list);
        return list;
    },
    addCertificate({ state, commit }, params) {
        let list = httpV2.post('/official/v1.0/certificate', params, this);
        return list;
    },
    editCertificate({ state, commit }, params) {
        let promise = httpV2.put(`/official/v1.0/certificate/${params.certificateId}`, params, this);
        return promise;
    },
    deleteCertificate({ state, commit }, params) {
        let promise = httpV2.delete("/official/v1.0/certificate", params, this);
        return promise;
    },
    //排序
    putSortCertificate({ state, commit }, params) {
        let promise = httpV2.put(`/official/v1.0/certificate/${params.id}/sort/${params.sort}`, params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
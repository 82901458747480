import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getGoodsPage({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/pagelist", params, this);
        return promise;
    },
    getGoodsClassTotal({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/class_set/list/tree", params, this);
        return promise;
    },
    getGoodsDetailFull({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/goods_detail/full", params, this);
        return promise;
    },
    checkFileExists({ state, commit }, params) {
        let promise = httpV2.post("/v1.0/goods_file/checkExists", params, this);
        return promise;
    },
    goodsEdit({ state, commit }, params) {
        let promise = httpV2.post("/v1.0/goods/edit", params, this);
        return promise;
    },
    goodsSync({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/" + params.tag, params, this);
        return promise;
    },
    goodsSearchByBarCode({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/list/bar_code", params, this);
        return promise;
    },
    getGoodsById({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/goods/" + params.id, params, this);
        return promise;
    },
    importSyncBarCodes({ state, commit }, params) {
        let promise = httpV2.postFileData("/v1.0/goods/import_sync", params, this);
        return promise;
    },
    getGoodsCountrysById({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/goods_home_recomand/edit", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
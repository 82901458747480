import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    tree({ state, commit }, params) {
        let promise = httpV2.get2("/official/v2.0/information/type/tree", params, this);
        return promise;
    },
    addInformation({ state, commit }, params) {
        let promise = httpV2.post("/official/v2.0/information/type", params, this);
        return promise;
    },
    getInformation({ state, commit }, params) {
        let promise = httpV2.get2(`/official/v2.0/information/type/${params.informationId}/type/${params.informationTypeId}`, params, this);
        return promise;
    },
    editInformation({ state, commit }, params) {
        let promise = httpV2.put(`/official/v2.0/information/type/${params.informationTypeId}`, params, this);
        return promise;
    },
    deleteInformation({ state, commit }, params) {
        let promise = httpV2.delete(`/official/v2.0/information/type/${params.id}`, params, this);
        return promise;
    },
    //排序
    putSortInformation({ state, commit }, params) {
        let promise = httpV2.put(`/official/v2.0/information/type/${params.id}/sort/${params.sort}`, params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const actions = {
    //获取注册用户列表
    getRegUser({ state, commit }, params) {
        let list = httpV2.get2('/v1.0/reg_user/page', params, this);
        return list;
    },
    updateRegUser({ state, commit }, params) {
        let promise = httpV2.patch(`/v1.0/reg_user/${params.userId}`, params, this);
        return promise;
    },
    notifyRegUser({ state, commit }, params) {
        let promise = httpV2.post(`/v1.0/reg_user/${params.userId}/notify`, params, this);
        return promise;
    },
    resetPassword({ state, commit }, params) {
        let promise = httpV2.patch(`/v1.0/busi_user/password`, params, this);
        return promise;
    },
    getBusiUser({ state, commit }, params) {
        let list = httpV2.get('/v1.0/busi_user/page', params, this);
        return list;
    },
    getUserAddress({ state, commit }, params) {
        let list = httpV2.get('/v1.0/address/page', params, this);
        return list;
    },
    getApiInfo({ state, commit }, params) {
        let list = httpV2.get( `/v1.0/application_material/`+params, {}, this);
        return list;
    },
    editApiInfo({ state, commit }, params) {
        let list = httpV2.put( `/v1.0/application_material`, params, this);
        return list;
    },
    

};

export default {
    getters,
    state,
    mutations,
    actions
};

export default {

    //成功码
    SUCCESS_CODE : 0,
    localLangage:'Aigo_APP_backOffice_language',//本地化语言 
    ossBaseUrl:'https://aigo-oss-euapp-test.oss-cn-shenzhen.aliyuncs.com/',
    accessToken:"b2b_access_token",
    currentTenant:"currentTenant",//当前租户,
    tanantMenu:"tanantMenu",//租户菜单
    userInfo:"userInfo",
  
   }

//    export  const  SUCCESS_CODE = 0